<script>
import VersionRowEnvironment from './VersionRowEnvironment';

export default {
  props: [
    'service',
    'environments',     // array of environment keys
    'environmentData',  // the actual data
  ],
  components: {
    VersionRowEnvironment
  },
  methods: {
    getEnvironmentData(env) {
      return this.environmentData[env][this.service];
    }
  }
}
</script>
<template>
  <tr>
    <td class="uk-width-1-5">{{ service }}</td>
    <version-row-environment
      v-for="env in environments" 
      :key="env"
      :env="env"
      :data="getEnvironmentData(env)"
    ></version-row-environment>
  </tr>
</template>
