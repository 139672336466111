import { createStore } from 'vuex'
import axios from 'axios';

export default createStore({
  state: {
    user: null
  },
  getters: {
    isLoggedIn(state) {
      return state.user != null;
    },
    currentUser(state) {
      return state.user;
    }
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    }
  },
  actions: {
    doLogin({commit}, form) {
      var self = this;
      return axios({
        method: 'post',
        url: process.env.VUE_APP_API_URL + '/api/login',
        data: form,
        withCredentials: true
      })
      .then((resp) => {
        commit('setUser', resp.data);
      })
      .catch((err) => {
        console.error('ERR!', err);
        throw err;
      });
    },
    doLogout({commit}) {
      var self = this;
      return axios({
        method: 'get',
        url: process.env.VUE_APP_API_URL + '/api/login/logout',
        withCredentials: true
      })
      .then((resp) => {
        commit('setUser', null);
      })
      .catch((err) => {
        console.error('ERR!', err);
        throw err;
      });
    },
    loadCurrentUser({commit}) {
      return axios({
        url: process.env.VUE_APP_API_URL + '/api/users'
      })
      .then(function(resp) {
        if(resp.data.id != '') {
          commit('setUser', resp.data);
        }
      })
      .catch(function(err) {
        console.error("Error in store - not logged in!", err);
        throw err;
      });
    }
  }
})
