<script>
export default {
  name: 'Home',
  components: {
  }
}
</script>

<template>
  <div class="uk-container">
    <h1>Welcome to Gantry</h1>
    Select something from the navigation above.
  </div>
</template>
