<script>
import axios from 'axios';
import { useToast } from "vue-toastification";
import LogRow from '../components/DeployLogs/LogRow';

const toast = useToast();

export default {
  components: {
    LogRow,
  },
  data() {
    return {
      loaded: false,
      deploys: [],
      currentPage: 1,
      hasMore: true,
    }
  },
  computed: {
    canLoadMore() {
      return this.hasMore;
    }
  },
  methods: {
    loadMoreDeploys() {
      this.currentPage += 1;
      this.loadDeploys();
    },
    loadDeploys() {
      var self = this;
      axios({
        method: 'get',
        url: process.env.VUE_APP_API_URL + '/api/deploys/logs?page=' + self.currentPage,
      })
        .then(function(resp) {
          self.deploys.push(...resp.data.items);
          self.hasMore = resp.data.meta.page.has_more;
          self.loaded = true;
        })
        .catch(function(err) {
          console.error("Error in loading logs", err);
        });
    },
  },
  created() {
    this.loadDeploys();
  }
}
</script>

<template>
  <div class="uk-container deploy-logs">
    <h1>Recent Deploys</h1>
    <div v-if="loaded">
      <table class="uk-table uk-table-divider uk-table-hover uk-table-small">
        <thead>
          <tr>
            <th>Last Updated</th>
            <th>User</th>
            <th>Service</th>
            <th>Version</th>
            <th>Environment</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <log-row v-for="(deploy, idx) in deploys" :key="idx" :deploy="deploy">
          </log-row>
        </tbody>
      </table>
      <div class="uk-flex uk-flex-center">
        <div>
          <button class="uk-button uk-button-primary" :disabled="!canLoadMore" @click="loadMoreDeploys">Load More</button>
        </div>
      </div>

    </div>
    <div class="uk-container" v-else>
      <div uk-spinner></div> Loading...
    </div>
  </div>
</template>
