import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Toast from "vue-toastification";

import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

UIkit.use(Icons);

import './css/main.scss';

// import 'uikit/dist/js/uikit-icons.min.js';

createApp(App)
    .use(store)
    .use(router)
    .use(Toast, {
        hideProgressBar: true,
    })
    .mount('#app');
