<script>
export default {
  props: [
    'env',
    'data',
  ],
  data() {
    return {
      uniqueModalName: '',
    };
  },
  computed: {
    hasData() {
      // console.log(this.env, this.data);
      return this.data != undefined && this.data != '';
    },
    currentlyRunningTaskdef() {
      var self = this;
      var matches = this.data.running_tasks.filter(function(task) {
        return task.name == self.data.taskdef.name && task.version == self.data.taskdef.version;
      });
      if(matches.length > 0) {
        return matches[0];
      }
      return null;
    },
    expectedTaskdefVersion() {
      return this.data.taskdef.version;
    },
    otherTaskdefVersions() {
      var self = this;
      return this.data.running_tasks.filter(function(task) {
        return task.version != self.data.taskdef.version;
      });
    },
    isRunningWell() {
      var etv = this.currentlyRunningTaskdef;
      if(etv != null) {
        return (etv.desiredCount == etv.runningCount) && etv.failedTasks == 0 && etv.pendingCount == 0;
      }
      return false;
    },
    isDeploying() {
      var etv = this.currentlyRunningTaskdef;
      if(etv != null) {
        // pendingCount check isn't strictly necessary, but is the most accurate way to detect a deployment
        return (etv.desiredCount != etv.runningCount) && etv.failedTasks == 0 && etv.pendingCount != 0;
      }
      return false;
    },
    isFailing() {
      var etv = this.currentlyRunningTaskdef;
      if(etv != null) {
        // there can be instances where a task is running but the deploy wasn't successful, so we skip checking desired vs running here
        return etv.failedTasks != 0 && etv.pendingCount >= 0;
      }
      return false;
    },
    isStalled() {
      var etv = this.currentlyRunningTaskdef;
      if(etv != null) {
        // this can happen when a service was disabled (0 desired tasks), then re-enabled, but is failing to boot successfully
        return etv.desiredCount > 0 && etv.runningCount == 0 && etv.failedTasks == 0 && etv.pendingCount == 0;
      }
      return false;
    },
    isDisabled() {
      var etv = this.currentlyRunningTaskdef;
      if(etv != null) {
        return etv.desiredCount == 0 && etv.runningCount == 0 && etv.failedTasks == 0 && etv.pendingCount == 0;
      }
      return false;
    },
    rowClasses() {
      return {
        'deploying' : this.isDeploying,
        'running-well': this.isRunningWell,
        'has-error': this.isFailing,
        'stalled': this.isStalled,
        'disabled': this.isDisabled,
      };
    },
    generateUkToggle() {
      return "target: #" + this.uniqueModalName;
    },
    hoverText() {
      return "Desired: " + this.currentlyRunningTaskdef['desiredCount'] + "\n" + 
             "Running: " + this.currentlyRunningTaskdef['runningCount'] + "\n" +
             "Pending: " + this.currentlyRunningTaskdef['pendingCount'] + "\n" +
             "Failed: " + this.currentlyRunningTaskdef['failedTasks'];
    },
  },
  created() {
    this.uniqueModalName = "a" + Math.random().toString(20).substr(2, 17);
  }
};
</script>
<template>
  <td v-if="hasData" class="data-cell" :class="rowClasses" :uk-toggle="generateUkToggle" :title="hoverText">
    <!-- {{ data }} -->
    Image version: {{ this.currentlyRunningTaskdef.imageVersion }}<br>
    Task def version {{ expectedTaskdefVersion }}<br>
    {{ currentlyRunningTaskdef['runningCount'] }} / {{ currentlyRunningTaskdef['desiredCount'] }} running

    <div :id="uniqueModalName" class="uk-modal-container" uk-modal>
        <div class="uk-modal-dialog uk-modal-body">
          <button class="uk-modal-close-default" type="button" uk-close></button>
          <h2 class="uk-modal-title">Details</h2>
          <div>
            <strong>Environment:</strong> {{ env }}<br><br>
            Expected Image: {{ this.currentlyRunningTaskdef['imageVersion'] }}<br>
            Expected Version: {{ this.currentlyRunningTaskdef['version'] }}
          </div>
          <table class="uk-table uk-table-divider">
            <thead>
              <tr>
                <th>Image Version</th>
                <th>Taskdef Version</th>
                <th># Desired</th>
                <th># Running</th>
                <th># Pending</th>
                <th># Failed</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="runningTask in this.data.running_tasks">
                <td>{{ runningTask['imageVersion'] }}</td>
                <td>{{ runningTask['version'] }}</td>
                <td>{{ runningTask['desiredCount'] }}</td>
                <td>{{ runningTask['runningCount'] }}</td>
                <td>{{ runningTask['pendingCount'] }}</td>
                <td>{{ runningTask['failedTasks'] }}</td>
              </tr>
            </tbody>
          </table>
        </div> <!-- body -->
    </div> <!-- modal -->
  </td>
  <td v-else>
    --
  </td>
</template>
