<script>
import { mapGetters, mapActions } from 'vuex';
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  computed: {
    ...mapGetters([
      'isLoggedIn',
      'currentUser'
    ])
  },
  methods: {
    handleLogout() {
      var self = this;
      this.doLogout()
        .then(() => {
          self.$router.push({ name: 'login' });
        })
        .catch(function(err) {
          console.error(err);
          toast.error("Logout failed. (lolwut?)");
        });
    },
    ...mapActions([
      'getCurrentUser',
      'doLogout',
    ])
  },
  created() {
    var self = this;
    this.$store.dispatch('loadCurrentUser')
      .catch((err) => {
        if(self.$route.name != 'login') {
          toast.warning("Please log in.");
          self.$router.push({ name: 'login' });
        }
    });
  }
}
</script>
<template>
  <div>
    <div class="uk-container uk-container-expand top-nav">
      <nav class="uk-navbar" uk-navbar>
        <div class="uk-navbar-left">
          <span class="uk-navbar-item uk-logo nav-logo">
            <img src="@/assets/gantry-nyshex.png" alt="Gantry" title="Gantry">
          </span>
          <ul class="uk-navbar-nav" v-if="isLoggedIn">
            <li><router-link :to="{name: 'home'}">Home</router-link></li>
            <li><router-link :to="{name: 'versions'}">Versions</router-link></li>
            <li>
              <router-link :to="{name: 'deploys'}">Deploys</router-link>  
              <div class="uk-navbar-dropdown">
                <ul class="uk-nav uk-navbar-dropdown-nav">
                  <li><router-link :to="{name: 'deploy_logs'}">Logs</router-link></li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
        
        <div class="uk-navbar-right" v-if="isLoggedIn">
          <ul class="uk-navbar-nav">
            <li>
              <a href="#">{{ currentUser.email }}</a>
              <div class="uk-navbar-dropdown">
                <ul class="uk-nav uk-navbar-dropdown-nav">
                  <li><a href="#" @click="handleLogout">Logout</a></li>
                </ul>
              </div>
            </li>
          </ul>
      </div>
      </nav>
    </div>
    <div class="uk-section">
      <router-view/>
    </div>
  </div>
</template>
 