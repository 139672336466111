<script>
import fetch from 'node-fetch';
import axios from 'axios';
import VersionRow from '../components/Versions/VersionRow';
import promiseRetry from 'promise-retry';

export default {
  components: {
    VersionRow,
  },
  data() {
    return {
      loaded: false,
      environments: {}
    }
  },
  computed: {
    getAllServices() {
      if(!this.loaded) {
        return {}
      }
      var keysets = [];
      for(var key in this.environments) {
        keysets = keysets.concat(Object.keys(this.environments[key]));
      }
      
      // unique
      return [...new Set(keysets)];
    },
    getAllEnvironments() {
      return ['test', 'staging', 'salesdemo', 'production'];
    }
  },
  methods: {
    loadVersions() {
      var self = this;
      var promises = [];
      ['test', 'staging', 'salesdemo', 'production'].forEach(function(env) {
        promises.push(self.loadOneVersion(env));
      });

      Promise.allSettled(promises)
        .then(function(values) {
          for(var elt in values) {
            var oneResult = values[elt];
            if (oneResult.status == 'fulfilled') {
              self.environments[oneResult.value['environment']] = oneResult.value['data'];
            }
          }
          self.loaded = true;
        })
        .catch(function(err) {
          console.error('Error in settling all promises', err);
        });
    },
    loadOneVersion(env) {
      var self = this;
      return promiseRetry((retry, number) => {
        return self.makeLoadOneVersionPromise(env)
            .catch(retry);
      }, {
        retries: 3,
        minTimeout: 2000
      })
      .then(function(resp) {
        return resp;
      }, function (err) {
        console.error("Error in loading one version:", env, err);
      });
    },
    makeLoadOneVersionPromise(env) {
      var self = this;

      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: process.env.VUE_APP_API_URL + '/api/versions?env=' + env,
          withCredentials: true,
          headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
        })
          .then(function(resp) {
            var json = resp.data;
            console.log('json is', json);
            if(json.status == 'pending') {
              reject('pending');
            } else {
              resolve({
                environment: env, 
                data: json,
              });
            }
          })
          .catch(function(err) {
            if(err.response.status >= 401 && err.response.status <= 403) {
              console.log('not logged in');
            } else {
              console.log('ERRCODE', err.response.status);
              console.error("Error in loading one version promise", env, err);
              reject(err);
            }
          });
      }); // promise
    },
    tableHeadClasses(env) {
      return [env.toLowerCase()];
    },
  },
  created() {
    this.loadVersions();
  }
}
</script>
<template>
  <div class="uk-container uk-container-expand versions" v-if="loaded">
    <h1>Versions</h1>
    <table class="uk-table uk-table-divider uk-table-middle uk-table-small">
      <thead>
        <tr>
          <th>Service</th>
          <th v-for="environment in getAllEnvironments" :class="tableHeadClasses(environment)">{{ environment }}</th>
        </tr>
      </thead>
      <tbody>
        <version-row
          v-for="service in getAllServices"
          :key="service"
          :service="service"
          :environments="getAllEnvironments"
          :environmentData="environments"
        ></version-row>
      </tbody>
    </table>
  </div>
  <div class="uk-container uk-container-expand versions" v-else>
    <div uk-spinner></div> Loading...
  </div>
</template>
