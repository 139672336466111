<script>
// import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import { useToast } from "vue-toastification";

const toast = useToast();

// const fetch = require('node-fetch')
// const tough = require('tough-cookie')
// const fetch = require('fetch-cookie')(nodeFetch, new tough.CookieJar());

// import fetch from 'node-fetch';
// import VersionRow from '../components/Versions/VersionRow';
// import promiseRetry from 'promise-retry';

export default {
  data() {
    return {
      form: {
        email: '',
        password: '',
      }
    }
  },
  computed: {
    ...mapGetters([
      'isLoggedIn',
    ])
  },
  methods: {
    submitLoginForm() {
      var self = this;
      this.doLogin(this.form)
        .then(function() {
          self.$router.push({ name: 'versions' });
        })
        .catch(function(err) {
          console.error(err);
          toast.error("Login failed.");
      
        });
    },
    ...mapActions([
      'doLogin',
    ])
  },
  watch: {
    isLoggedIn(newValue) {
      // if the user is already logged in, then go to the homepage
      if(newValue) {
        this.$router.push({ name: 'home' });
      }
    }
  }
}
</script>
<template>
  <div class="uk-container">
    <h1>Login</h1>
    <form class="uk-form-horizontal" @submit.prevent="submitLoginForm">
      <div class="uk-margin">
        <label class="uk-form-label">Email</label>
        <div class="uk-form-controls">
          <input class="uk-input" type="text" v-model="form.email" placeholder="Email">
        </div>
      </div>
      <div class="uk-margin">
        <label class="uk-form-label">Password</label>
        <div class="uk-form-controls">
          <input class="uk-input" type="password" v-model="form.password" placeholder="Password">
        </div>
      </div>
      <div class="uk-margin">
        <label class="uk-form-label"></label>
        <div class="uk-form-controls">
          <input class="uk-button" type="submit" value="Login">
        </div>
      </div>
    </form>
  </div>
</template>
