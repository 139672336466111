import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Versions from '../views/Versions.vue'
import Deploys from '../views/Deploys.vue'
import DeployLogs from '../views/DeployLogs.vue'
import Login from '../views/Login.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/versions',
    name: 'versions',
    component: Versions
  },
  {
    path: '/deploys',
    name: 'deploys',
    component: Deploys
  },
  {
    path: '/deploys/logs',
    name: 'deploy_logs',
    component: DeployLogs
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
})

export default router
