<script>
import { parseISO, format, formatDistance } from 'date-fns';
export default {
  props: ['deploy'],
  computed: {
    rowClasses() {
      return {
        'is-error': this.deploy.status == 'error',
        'is-complete': this.deploy.status == 'complete',
      }
    }
  },
  methods: {
    displayDate(date) {
      var parsed = parseISO(date + 'Z'); // <-- database values are in UTC
      return format(parsed, 'PPpp');
    },
    displayDateAgo(date) {
      var parsed = parseISO(date + 'Z');
      return formatDistance(parsed, new Date(), {addSuffix: true});
    },
  },
}
</script>
<template>
  <tr :class="rowClasses">
    <td :title="deploy.id">
      {{ displayDate(deploy.updated_at) }}<br>
      <small>({{ displayDateAgo(deploy.updated_at) }})</small>
    </td>
    <td>{{ deploy.user.name }}</td>
    <td>{{ deploy.service }}</td>
    <td>{{ deploy.version }}</td>
    <td>{{ deploy.environment }}</td>
    <td>{{ deploy.status }}</td>
  </tr>
</template>
