<script>
import axios from 'axios';
import fetch from 'node-fetch';
import { useToast } from "vue-toastification";

const toast = useToast();

export default {
  data() {
    return {
      servicesLoaded: false,
      confirmDeployToProduction: false,
      form: {
        environment: 'staging',
        service: '',
        version: '',
      },
      services: {},
      versions: {},
      environments: {
        staging: 'Staging',
        salesdemo: 'Salesdemo',
        test: 'Test',
        production: '* Production *',
      },
    }
  },
  computed: {
    formIsValid() {
      return this.form.environment != ''
              && this.form.service != ''
              && this.form.version != ''
      ;
    }
  },
  methods: {
    blankOutForm() {
      this.form.environment = '';
      this.form.service = '';
      this.form.version = '';
    },
    deploy() {
      console.log(this.form.environment);
      if(this.form.environment == 'production') {
        this.confirmDeployToProduction = true;
      } else {
        this.runDeploy();
      }
    },
    runDeploy() {
      var self = this;
      // prevent dev image tag to prod or salesdemo deploy
      if(self.form.version.startsWith('dev')) {
        if(self.form.environment == 'salesdemo' || self.form.environment == 'production') {
          toast.error(`Cannot deploy dev images to ${self.form.environment}!`);
          return;
        }
      }
      axios({
        method: 'post',
        url: process.env.VUE_APP_API_URL + '/api/deploys',
        data: this.form,
      })
      .then(function(resp) {
        console.log('deploy result', resp);
        toast.success("Deploy for " + self.form.service + " to " + self.form.environment + " has been queued.");
        self.blankOutForm();
      })
      .catch(function(err) {
        console.error("Error in running a deploy", err);
        toast.error("Deploy failed! Check the logs.");
        self.blankOutForm();
      });
    },
    versionsLoadedForService(svc) {
      return (svc in this.versions);
    },
    loadServices() {
      var self = this;
      axios({
        method: 'get',
        url: process.env.VUE_APP_API_URL + '/api/deploys/services',
      })
        .then(function(resp) {
          self.services = resp.data;
          self.servicesLoaded = true;
        })
        .catch(function(err) {
          console.error("Error in loading services", err);
        });
    },
    loadVersions() {
      var self = this;
      if(this.form.service == "") {
        return;
      }
      axios({
        method: 'get',
        url: process.env.VUE_APP_API_URL + '/api/deploys/images?service=' + this.form.service
      })
        .then(function(resp) {
          self.versions[self.form.service] = resp.data;
        })
        .catch(function(err) {
          console.error("Error in loading versions", self.form.service, err);
        });
    }
  },
  watch: {
    'form.service': function(newVal) {
      this.form.version = '';
      this.loadVersions(newVal);
    },
    'form.environment': function(newVal) {
      this.confirmDeployToProduction = false;
    },
  },
  created() {
    this.loadServices();
  }
}
</script>

<template>
  <div class="uk-container">
    <div class="uk-flex uk-flex-between">
      <div class="uk-flex-left">
        <h1>Deploy</h1>
      </div>
      <div class="uk-flex-right">
        <router-link :to="{name: 'deploy_logs'}" class="uk-button uk-button-link">Recent Deploys</router-link>
      </div>
    </div>
    <div v-if="servicesLoaded">
      <form class="uk-form-horizontal">
        <div class="uk-margin">
          <label class="uk-form-label">Service</label>
          <div class="uk-form-controls">
            <select class="uk-select" v-model="form.service">
              <option value="">Select a service</option>
              <option v-for="(service, idx) in services" :key="idx" :value="idx">{{ idx }}</option>
            </select>
          </div>
        </div>
        <div class="uk-margin">
          <div class="uk-form-label">Version to Deploy</div>
          <div class="uk-form-controls">
            <select class="uk-select" v-model="form.version" v-if="versionsLoadedForService(form.service)">
              <option value="">Select a version</option>
              <option v-for="(version, idx) in versions[form.service]['images']" :key="idx" :value="version['version']">{{ version['version'] }} ({{ version['publish_date'] }})</option>
            </select>
            <div v-else>
              --
            </div>
          </div>
        </div>
        <div class="uk-margin">
          <label class="uk-form-label">Environment</label>
          <div class="uk-form-controls">
            <select class="uk-select" v-model="form.environment">
              <option v-for="(display, env) in environments" :key="env" :value="env">{{ display }}</option>
            </select>
          </div>
        </div>
        <div class="uk-margin">
          <label class="uk-form-label"></label>
          <div class="uk-form-controls">
            <button :disabled="!formIsValid" type="button" class="uk-button uk-button-secondary" @click="deploy">Deploy</button>
            <div class="uk-margin" v-if="confirmDeployToProduction">
              <button type="button" class="uk-button uk-button-danger" @click="runDeploy">
                <span uk-icon="warning"></span> Confirm deploy to Production!
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="uk-container" v-else>
      <div uk-spinner></div> Loading...
    </div>
  </div>
</template>
